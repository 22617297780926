import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import theme from '@/styles/theme';

type Props = {
  pageCount: number;
  spMode: boolean;
  currentPage: number;
  onClickPager: (selected: number) => void;
  isDisablePager: boolean;
};

const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
  disabledClassName: 'disabledButton',
})`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  
  li a {
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    text-align: left;
    font: ${theme.fonts.bold_16_45};
    letter-spacing: 0px;
    opacity: 1;
    margin:1vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  }
  li.previous a,
  li.next a {
    display:none;
  },
  li.break a {
    border-color: transparent;
    color: ${theme.colors.background}
  }
  li.active a {
    background: ${theme.colors.slash} 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: left;
    font: ${theme.fonts.bold_16_45};
    letter-spacing: 0px;
    color: #ffffff;
  }
  li.disabledButton a {
    display:none;
    cursor: default;
  }
  li.disable
`;

const Pager: React.VFC<Props> = (props: Props) => {
  return (
    <MyPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      pageCount={props.isDisablePager ? 0 : props.pageCount}
      marginPagesDisplayed={3}
      pageRangeDisplayed={2}
      onPageChange={(page) => props.onClickPager(page.selected)}
      disabledClassName="disabledButton"
      activeClassName="active"
      forcePage={props.currentPage - 1}
    ></MyPaginate>
  );
};

export default Pager;
