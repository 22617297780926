import React from 'react';
import styled from 'styled-components';
import { FormState, schema } from './state';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Breakpoints } from '@/constants/constants';
import CenterModal from '@/components/atoms/CenterModal';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import { SpBr } from '@/components/atoms/CommonStyles';
import { GetLoanableAmountsStateParam } from '@/models/request/loanableAmounts';
import theme from '@/styles/theme';
import ErrorMessage from '@/components/atoms/ErrorMessage';

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  onChangeTrialMode: () => void;
  setLoanableAmountsParam: (data: GetLoanableAmountsStateParam) => void;
};

const Title = styled.div`
  margin-bottom: 40px;
  font: ${theme.fonts.bold_30_45};
  color: ${theme.colors.blue};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 40px;
    font: ${theme.fonts.bold_22_33};
  }
`;

const Message = styled.p`
  margin-bottom: 60px;
  text-align: center;
  font: ${theme.fonts.normal_16_32};
  color: ${theme.colors.text};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 30px;
  }
`;

const FormArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: block;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
`;

const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:not(:last-child) {
    margin-right: 60px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    &:not(:last-child) {
      margin-right: 0;
    }
    padding: 0 10px;
  }
`;

const Label = styled.label`
  margin-right: 20px;
  font: ${theme.fonts.bold_16_32};
  color: ${theme.colors.blue};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-right: auto;
    margin-bottom: 15px;
  }
`;

const InputAndUnit = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 40px;
  overflow: visible;
  white-space: nowrap;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    align-items: start;
    width: calc(100% - 35px);
  }
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  margin-right: 10px;
  padding: 8px 10px;
  border: 1px solid ${theme.colors.inputBorder};
  border-radius: 4px;
  font: ${theme.fonts.normal_16_32};
`;

const Unit = styled.span`
  font: ${theme.fonts.normal_16_32};
  color: ${theme.colors.text};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    text-align: right;
    width: 35px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-top: 30px;
    display: block;
  }
`;

const ConfirmButton = styled.button`
  width: 407px;
  height: 64px;
  background: ${theme.colors.gold} 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: ${theme.fonts.bold_22_33};
  color: ${theme.colors.white};

  :disabled {
    background: ${theme.colors.inputBorder};
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 86px;
    margin-bottom: 20px;
  }
`;

const Kome = styled.span`
  vertical-align: baseline;
  font: ${theme.fonts.bold_12_23};
  color: ${theme.colors.white};
`;

const Information = styled.a`
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
`;

const InformationText = styled.span`
  margin-left: 11px;
  font: ${theme.fonts.normal_12_22};
  color: ${theme.colors.black};
`;

const TrialFormModal = (props: Props) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormState> = (data) => {
    props.setLoanableAmountsParam({
      annualIncome: Number(data.annualIncome),
      totalLoanAmount: data.totalLoanAmount,
      age: data.age,
    });
    props.onChangeTrialMode();
    props.closeModal();
  };

  return (
    <CenterModal heading="heading" description="description" isOpen={props.isModalOpen} onCloseClick={props.closeModal}>
      <Title id="heading">購入可能物件数を確認する</Title>
      <Message id="description">ご入力いただいた情報から、借入可能金額および購入可能物件を算出します。</Message>
      <FormArea>
        <Form>
          <Label htmlFor="annualIncome">年収</Label>
          <InputAndUnit>
            <InputWrapper>
              <Controller
                name="annualIncome"
                control={control}
                render={({ field }) => <Input type="text" id="annualIncome" {...field} />}
              />
              {errors?.annualIncome && <ErrorMessage>{errors.annualIncome.message}</ErrorMessage>}
            </InputWrapper>
            <Unit>万円</Unit>
          </InputAndUnit>
        </Form>
        <Form>
          <Label htmlFor="age">年齢</Label>
          <InputAndUnit>
            <InputWrapper>
              <Controller
                name="age"
                control={control}
                render={({ field }) => <Input type="text" id="age" {...field} />}
              />
              {errors?.age && <ErrorMessage>{errors.age.message}</ErrorMessage>}
            </InputWrapper>
            <Unit>歳</Unit>
          </InputAndUnit>
        </Form>
        <Form>
          <Label htmlFor="totalLoanAmount">総借入金額</Label>
          <InputAndUnit>
            <InputWrapper>
              <Controller
                name="totalLoanAmount"
                control={control}
                render={({ field }) => <Input type="text" id="totalLoanAmount" {...field} />}
              />
              {errors?.totalLoanAmount && <ErrorMessage>{errors.totalLoanAmount.message}</ErrorMessage>}
            </InputWrapper>
            <Unit>万円</Unit>
          </InputAndUnit>
        </Form>
      </FormArea>
      <ButtonWrapper>
        <ConfirmButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          購入可能物件数<Kome>※</Kome>
          <SpBr />
          を確認する
        </ConfirmButton>
      </ButtonWrapper>
      <Information>
        <InfoIcon color="white" />
        <InformationText>
          ※ 購入可能物件は年齢、収入、借入金額などをもとに算出したもので、購入できることを保証するものではありません。
        </InformationText>
      </Information>
    </CenterModal>
  );
};

export default TrialFormModal;
