import React, { useEffect, useState } from 'react';
import Pager from '@/components/atoms/Pager';
import * as numberFormatter from '../../utils/numberFormatter';
import { Property, PropertyStatus } from 'openapi/kusabi-frontgw-spec';
import styled from 'styled-components';
import flexCenter from '@/styles/mixins/flexCenter';
import theme from '@/styles/theme';
import { Routings } from '@/common/routings';
import { Breakpoints, QueryStrings, ScreenFormat } from '@/constants/constants';
import * as util from '@/common/utilities';
import ArrowIcon from '../atoms/Icons/ArrowIcon';
import PropertyMarker from './Buyer/Property/Common/Marker';
import CameraIcon from '../atoms/Icons/CameraIcon';
import RegistrationGuideArea from './Buyer/RegistrationGuideArea';
import ChevronLeft from '../atoms/Icons/ChevronLeft';
import ChevronRight from '../atoms/Icons/ChevronRight';
import { estimatedAnnualIncome } from '@/common/businessCalc';
import { numberToJpYenFormat } from '../../utils/numberFormatter';

type Props = {
  properties: Property[];
  isRegistered: boolean;
  isOnSale: boolean;
  isDisablePager: boolean;
  spMode: boolean;
  isSort: boolean;
  chengeIsSort: (sort: boolean) => void;
};

const MansionName = styled.div`
  text-align: left;
  font: ${theme.fonts.bold_16_27};
  letter-spacing: 0px;
  color: ${theme.colors.black};
  display: flex;
  justify-content: space-between;
`;

const CatchCopy = styled.div`
  text-align: left;
  font: ${theme.fonts.normal_12_22};
  letter-spacing: 0px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const MansionDataText = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_10_22};
  letter-spacing: 0px;
  color: ${theme.colors.black};
  opacity: 1;
  padding-top: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  p {
    border-right: 1px solid ${theme.colors.buttonBorder};
    padding-right: 1vw;
    margin: 0;
    opacity: 1;
  }
`;

const LinkedArea = styled.a`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  height: 100%;
`;

const PropertyList = styled.ul<{ isTrialMode?: boolean }>`
  position: relative;
  ${({ isTrialMode }) => isTrialMode && 'padding-bottom: 200px'};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    ${({ isTrialMode }) => isTrialMode && 'padding-bottom: 300px'};
  }
`;

const PropertyItemArea = styled.li`
  padding: 7.5px 0;
  height: 211px;
  display: flex;
  width: 100%;
`;

const PropertyItem = styled.div`
  position: relative;
  width: 100%;
  background: ${theme.colors.white};
`;

const ItemLeftArea = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ItemCenterArea = styled.div`
  opacity: 1;
  width: 50%;
  min-width: 380px;
  height: 100%;
  padding: 20px 2vw;
  text-align: left;
  font: ${theme.fonts.normal_12_22};
  letter-spacing: 0px;
  color: ${theme.colors.black};
`;

const ItemRightArea = styled.div`
  background: ${theme.colors.background} 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 25%;
  height: 100%;
  padding: 0 1.5vw;
  font: ${theme.fonts.bold_12_45};
  flex-direction: column;
  color: ${theme.colors.white};
  label {
    text-align: left;
    font: ${theme.fonts.bold_20_45};
    letter-spacing: 0px;
  }
`;

const ItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SpPropertyWrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70%;
`;

const SpPropertyWrapperMiddle = styled.div`
  font: ${theme.fonts.normal_12_22};
  margin-top: 1%;
`;

const SpPropertyWrapperBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ImageWrapper = styled.div`
  background: ${theme.colors.imageBackground};
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 48%;
    aspect-ratio: 4 / 3;
  }
`;

const PropertyImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PropertyInfoArea = styled.div`
  width: 48%;
  background: ${theme.colors.white};
`;

const SpPropertyItem = styled.div`
  height: fit-content;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 20px 15px;
  text-align: left;
  font: ${theme.fonts.normal_12_22};
  letter-spacing: 0px;
  color: ${theme.colors.black};
`;

const SpBottomArea = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${theme.colors.background};
  height: 82px;
  padding: 0 15px;
  font: ${theme.fonts.bold_12_45};
  color: ${theme.colors.white};
  margin-bottom: 15px;
`;

const SpItemTitle = styled.div`
  text-align: left;
  font: ${theme.fonts.bold_16_27};
  letter-spacing: 0px;
  color: ${theme.colors.black};
  border: 0;
  opacity: 1;
`;

const SpItemIcon = styled.div`
  ${flexCenter}
  background: ${theme.colors.tableBorder};
  text-align: left;
  font: ${theme.fonts.bold_10_22};
  letter-spacing: 0px;
  color: ${theme.colors.white};
  opacity: 1;
  width: 20px;
  height: 20px;
  margin-right: 2vw;
`;

const SpItemDataWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const SpItemData = styled.div`
  display: flex;
  margin: 1% 5vw 0px 0px;
`;

const SpPriceWrapper = styled.div`
  width: 40vw;
  height: 41px;
  font: ${theme.fonts.bold_12_45};
`;

const SpPrice = styled.div`
  display: flex;
  font: ${theme.fonts.black_20_30};
  align-items: baseline;
  color: ${theme.colors.white};
`;

const SpPriceUnitLabel = styled.div`
  font: ${theme.fonts.black_12_1};
`;

const SpPriceLabel = styled.div`
  margin-top: 15px;
  font: ${theme.fonts.bold_12_23};
`;

const ArrowIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-bottom: 15px;
  }
`;

const SpPagerWrapper = styled.div`
  width: 90vw;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  font: ${theme.fonts.normal_12_22};
`;

const SpCatchCopyLabel = styled.div`
  text-align: left;
  font: ${theme.fonts.normal_12_22};
  letter-spacing: 0px;
  color: ${theme.colors.gold};
  opacity: 1;
`;

const SpLinkArea = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
`;

const PropertyDetailTitle = styled.div`
  text-align: left;
  font: ${theme.fonts.bold_10_22};
  letter-spacing: 0px;
  color: ${theme.colors.black};
  padding-right: 0.6vw;
`;

const NextButton = styled.button`
  border: 1px solid ${theme.colors.buttonBorder};
  border-radius: 4px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  font: ${theme.fonts.bold_16_45};
  color: ${theme.colors.background};
  width: 76px;
  height: 40px;
  ${flexCenter}
`;

const BottomButtonWrapper = styled.div`
  width: 96px;
  ${flexCenter}
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
  height: 100%;
  background: ${theme.colors.imageBackground};
  font: ${theme.fonts.bold_16_20};
  color: #a2aab0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    row-gap: 10px;
    width: 48%;
    aspect-ratio: 4 / 3;
    padding: 3%;
    font: ${theme.fonts.bold_16_20};
  }
`;

const PagerWrapper = styled.div`
  ${flexCenter}
  padding-top: 2vw;
`;

const Styled1RegistrationGuideArea = styled(RegistrationGuideArea)`
  position: static;
`;

const Styled2RegistrationGuideArea = styled(RegistrationGuideArea)`
  top: 260px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    top: 310px;
  }
`;

/** 一ページあたりの物件表示件数 */
const PER_PAGE = 20;

const MansionList = ({ properties, isRegistered, isOnSale, isDisablePager, spMode, isSort, chengeIsSort }: Props) => {
  const [pageCount, setPageCount] = useState<number>(Math.ceil(properties.length / PER_PAGE));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const screenFormat = isOnSale ? ScreenFormat.sale : ScreenFormat.contract;
  const [salesPropertiesState, setSalesPropertiesState] = useState<Property[]>([]);

  const queryStringPartnerId = util.generateQueryStringPartnerId();
  const queryStringPartnerIdWithAmp = queryStringPartnerId && `&${queryStringPartnerId}`;

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(properties.length / PER_PAGE));
    if (!isRegistered && isOnSale && properties.length > 0) {
      // お試しの売出し中物件は4件目以降は表示しない
      setSalesPropertiesState(properties.filter((_, i) => i < 3));
    } else {
      setSalesPropertiesState(properties);
    }
  }, [properties]);

  useEffect(() => {
    if (isSort) {
      chengeIsSort(false);
      handlePageClick(0);
    }
  }, [isSort]);

  // ページング
  const handlePageClick = (page: number) => {
    if (!isRegistered && isOnSale) {
      // 会員未登録のユーザに売出し中物件一覧でページ遷移させないためにreturnする
      return;
    }
    setCurrentPage(page + 1);
    tabScroll();
    const loanableAmountsArea = document.getElementById('loanableAmountsArea');
    loanableAmountsArea?.scrollIntoView({ behavior: 'smooth' });
  };

  const tabScroll = () => {
    const tabs = document.getElementById('tabs');
    tabs?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const pricesFormatter = (price: number) => {
    const [okuYen, manYen] = numberFormatter.numberToJpYenPrice(price);
    return (
      <SpPrice>
        {okuYen && (
          <>
            {okuYen}
            <SpPriceUnitLabel>億</SpPriceUnitLabel>
          </>
        )}
        {manYen && (
          <>
            {manYen}
            <SpPriceUnitLabel>万</SpPriceUnitLabel>
          </>
        )}
        {/* 0 円以下だった場合 */}
        {!okuYen && !manYen && <label>0</label>}
        <SpPriceUnitLabel>円</SpPriceUnitLabel>
      </SpPrice>
    );
  };

  const handleOnButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.currentTarget.id === 'before') {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
    tabScroll();
  };

  return (
    <>
      {spMode ? (
        <>
          <PropertyList isTrialMode={!isRegistered && isOnSale && salesPropertiesState.length === 2}>
            {/* 選択されているページに応じたデータのみを抽出 */}
            {salesPropertiesState
              .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
              .map((property: Property, index: number) => (
                <li key={property.id} style={{ position: 'relative' }}>
                  <SpLinkArea
                    target="_self"
                    style={{ filter: !isRegistered && isOnSale && index !== 0 ? 'blur(4px)' : '0px' }}
                    href={
                      !isRegistered && isOnSale
                        ? undefined
                        : `${Routings.buyerProperty.location}?${QueryStrings.SCREEN_FORMAT}=${screenFormat}&${QueryStrings.PROPERTY_ID}=${property.id}${queryStringPartnerIdWithAmp}`
                    }
                  >
                    <SpPropertyItem>
                      <SpPropertyWrapperTop>
                        {property && property.propertyImages && property.propertyImages[0].s3ObjectKey ? (
                          <ImageWrapper>
                            <PropertyImage src={util.cdnPath(property.propertyImages[0].s3ObjectKey)} alt="" />
                          </ImageWrapper>
                        ) : (
                          <NoImage>
                            <CameraIcon />
                            NO IMAGE
                          </NoImage>
                        )}
                        <PropertyInfoArea>
                          <SpItemTitle>{`${property.mansionName}`}</SpItemTitle>
                          <PropertyMarker
                            propertyStatus={property.propertyStatus as PropertyStatus}
                            isNew={property.isNew}
                            hasPriceChanged={property.hasPriceChanged}
                          />
                          <SpCatchCopyLabel>{`${property.catchCopy}`}</SpCatchCopyLabel>
                        </PropertyInfoArea>
                      </SpPropertyWrapperTop>
                      <SpPropertyWrapperMiddle>
                        {`${property.prefectureName}` + `${property.cityName}` + `${property.townName}`}
                      </SpPropertyWrapperMiddle>
                      <SpPropertyWrapperBottom>
                        <SpItemDataWrapper>
                          <SpItemData>
                            <SpItemIcon>築</SpItemIcon>
                            {property.builtDate && numberFormatter.yyyymmToJpFormatWithBuildingAge(property.builtDate)}
                          </SpItemData>
                          <SpItemData>
                            <SpItemIcon>面</SpItemIcon>
                            {`${property.unitSize}`} ㎡
                          </SpItemData>
                          <SpItemData>
                            <SpItemIcon>階</SpItemIcon>
                            {`${property.story}`} 階
                          </SpItemData>
                        </SpItemDataWrapper>
                      </SpPropertyWrapperBottom>
                    </SpPropertyItem>
                    <SpBottomArea>
                      <SpPriceWrapper>
                        <SpPriceLabel>価格</SpPriceLabel>
                        {property.salesPrice && pricesFormatter(property.salesPrice)}
                      </SpPriceWrapper>
                      <SpPriceWrapper>
                        <SpPriceLabel>想定年間収入</SpPriceLabel>
                        <div>
                          <SpPrice>
                            {numberToJpYenFormat(estimatedAnnualIncome(property.rentPrice) / 10000)}
                            <SpPriceUnitLabel>万円</SpPriceUnitLabel>
                          </SpPrice>
                        </div>
                      </SpPriceWrapper>
                      <ArrowIconWrapper>
                        <ArrowIcon />
                      </ArrowIconWrapper>
                    </SpBottomArea>
                  </SpLinkArea>
                </li>
              ))}
            {/* お試し機能で売出し中物件を表示時、会員登録画面への誘導ガイドを表示する */}
            {!isRegistered && isOnSale && salesPropertiesState.length <= 1 && <Styled1RegistrationGuideArea />}
            {!isRegistered && isOnSale && salesPropertiesState.length >= 2 && <Styled2RegistrationGuideArea />}
          </PropertyList>

          {!(!isRegistered && isOnSale) && salesPropertiesState.length > 0 && (
            <SpCenterWrapper>
              <SpPagerWrapper>
                <BottomButtonWrapper style={{ width: '40px' }}>
                  {currentPage != 1 && (
                    <NextButton
                      aria-label="前へ"
                      style={{ width: '40px' }}
                      id="before"
                      onClick={(e) => handleOnButtonClick(e)}
                    >
                      <ChevronLeft />
                    </NextButton>
                  )}
                </BottomButtonWrapper>
                <Pager
                  pageCount={pageCount}
                  onClickPager={handlePageClick}
                  isDisablePager={isDisablePager}
                  spMode={true}
                  currentPage={currentPage}
                />
                <BottomButtonWrapper style={{ width: '40px' }}>
                  {currentPage != pageCount && (
                    <NextButton aria-label="次へ" style={{ width: '40px' }} onClick={(e) => handleOnButtonClick(e)}>
                      <ChevronRight />
                    </NextButton>
                  )}
                </BottomButtonWrapper>
              </SpPagerWrapper>
            </SpCenterWrapper>
          )}
        </>
      ) : (
        <>
          <PropertyList isTrialMode={!isRegistered && isOnSale && salesPropertiesState.length === 2}>
            {/* 選択されているページに応じたデータのみを抽出 */}
            {salesPropertiesState
              .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
              .map((property: Property, index: number) => (
                <PropertyItemArea key={property.id}>
                  <PropertyItem>
                    <LinkedArea
                      target="_self"
                      style={{ filter: !isRegistered && isOnSale && index !== 0 ? 'blur(4px)' : '0px' }}
                      href={
                        !isRegistered && isOnSale
                          ? undefined
                          : `${Routings.buyerProperty.location}?${QueryStrings.SCREEN_FORMAT}=${screenFormat}&${QueryStrings.PROPERTY_ID}=${property.id}${queryStringPartnerIdWithAmp}`
                      }
                    >
                      <ItemLeftArea>
                        {property && property.propertyImages && property.propertyImages[0].s3ObjectKey ? (
                          <ImageWrapper>
                            <PropertyImage src={util.cdnPath(property.propertyImages[0].s3ObjectKey)} alt="" />
                          </ImageWrapper>
                        ) : (
                          <NoImage>
                            <CameraIcon />
                            NO IMAGE
                          </NoImage>
                        )}
                      </ItemLeftArea>
                      <ItemCenterArea>
                        <MansionName>
                          {`${property.mansionName}`}
                          <PropertyMarker
                            propertyStatus={property.propertyStatus as PropertyStatus}
                            isNew={property.isNew}
                            hasPriceChanged={property.hasPriceChanged}
                          />
                        </MansionName>
                        <CatchCopy>{property.catchCopy}</CatchCopy>
                        <div>{`${property.prefectureName}` + `${property.cityName}` + `${property.townName}`}</div>
                        <MansionDataText>
                          <p>
                            <SpCenterWrapper>
                              <PropertyDetailTitle>築年月</PropertyDetailTitle>
                              {property.builtDate &&
                                numberFormatter.yyyymmToJpFormatWithBuildingAge(property.builtDate)}
                            </SpCenterWrapper>
                          </p>
                          <p>
                            <SpCenterWrapper>
                              <PropertyDetailTitle>専有面積</PropertyDetailTitle>
                              {`${property.unitSize}`} ㎡
                            </SpCenterWrapper>
                          </p>
                          <SpCenterWrapper>
                            {isOnSale ? (
                              <>
                                <PropertyDetailTitle>所在階</PropertyDetailTitle> {`${property.story}`} 階
                              </>
                            ) : (
                              <>
                                <PropertyDetailTitle>成約年月</PropertyDetailTitle>
                                {property.contractDate &&
                                  numberFormatter.yyyymmdddToJpFormatYYYYMM(property.contractDate)}
                              </>
                            )}
                          </SpCenterWrapper>
                        </MansionDataText>
                      </ItemCenterArea>
                      <ItemRightArea>
                        <div>価格</div>
                        <div>{property.salesPrice && pricesFormatter(property.salesPrice)}</div>
                        <div>想定年間収入</div>
                        <ItemRight>
                          <div>
                            <SpPrice>
                              {numberToJpYenFormat(estimatedAnnualIncome(property.rentPrice) / 10000)}
                              <SpPriceUnitLabel>万円</SpPriceUnitLabel>
                            </SpPrice>
                          </div>
                          <ArrowIconWrapper>
                            <ArrowIcon />
                          </ArrowIconWrapper>
                        </ItemRight>
                      </ItemRightArea>
                    </LinkedArea>
                  </PropertyItem>
                </PropertyItemArea>
              ))}
            {/* お試し機能で売出し中物件を表示時、会員登録画面への誘導ガイドを表示する */}
            {!isRegistered && isOnSale && salesPropertiesState.length <= 1 && <Styled1RegistrationGuideArea />}
            {!isRegistered && isOnSale && salesPropertiesState.length >= 2 && <Styled2RegistrationGuideArea />}
          </PropertyList>
          {!(!isRegistered && isOnSale) && salesPropertiesState.length > 0 && (
            <PagerWrapper>
              <BottomButtonWrapper>
                {currentPage != 1 && (
                  <NextButton id="before" onClick={(e) => handleOnButtonClick(e)}>
                    <ChevronLeft />
                    前へ
                  </NextButton>
                )}
              </BottomButtonWrapper>
              <Pager
                pageCount={pageCount}
                onClickPager={handlePageClick}
                isDisablePager={isDisablePager}
                spMode={false}
                currentPage={currentPage}
              />
              <BottomButtonWrapper>
                {currentPage != pageCount && (
                  <NextButton onClick={(e) => handleOnButtonClick(e)}>
                    次へ
                    <ChevronRight />
                  </NextButton>
                )}
              </BottomButtonWrapper>
            </PagerWrapper>
          )}
        </>
      )}
    </>
  );
};

export default MansionList;
