import theme from '@/styles/theme';
import React, { memo } from 'react';

const colors = theme.colors;

type Props = {
  color?: keyof typeof colors;
};

const ChevronLeft = ({ color = 'gold' }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={colors[color]}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    </svg>
  );
};

export default memo(ChevronLeft);
