import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Routings } from '@/common/routings';
import { toVw } from '@/common/utilities';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import theme from '@/styles/theme';
import { Breakpoints } from '@/constants/constants';
import TriangleArrowIcon from '@/components/atoms/Icons/TriangleArrowIcon';

const Wrapper = styled.div`
  width: 86%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 28px 0;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 110px 25px;
  }
`;

const AdjustIcon = styled.span`
  display: inline-block;
  width: 25px;
  text-align: left;
  text-indent: 0;
`;

const GuideMessage = styled.p`
  font: ${theme.fonts.normal_12_22};
  color: #222222;
  text-indent: -25px;
  padding-left: 25px;
  & > em {
    font: ${theme.fonts.bold_12_22};
    color: ${theme.colors.gold};
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.normal_12_20};
    & > em {
      font: ${theme.fonts.bold_12_22};
    }
  }
`;

const FeatureList = styled.dl`
  width: ${toVw(626)};
  text-align: left;
  margin-top: 33px;
  margin-bottom: 30px;
  font: ${theme.fonts.normal_16_32};
  color: #333333;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    font: ${theme.fonts.normal_16_32};
    margin-top: 30px;
    margin-bottom: 31px;
  }
`;

const FeatureHeading = styled.dt``;

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > *:first-child {
    margin-right: 4px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    align-items: flex-start;
    font: ${theme.fonts.normal_14_24};
  }
`;

const FeatureListIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
`;

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 44px;
  border-radius: 4px;
  text-decoration: none;
  color: ${theme.colors.white};
  background: ${theme.colors.blue};
  font: ${theme.fonts.normal_16_32};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 44px;
    font: ${theme.fonts.bold_16_32};
  }
`;

type Props = {
  className?: string;
};

const RegistrationGuideArea = (props: Props) => {
  const { className } = props;
  return (
    <Wrapper className={className}>
      <GuideMessage>
        <AdjustIcon>
          <InfoIcon color="white" />
        </AdjustIcon>
        すべての売出し中物件をご覧いただくには、<em>会員登録が必要</em>です。
      </GuideMessage>
      <FeatureList>
        <FeatureHeading>KUSABI会員になると、以下の機能がご利用いただけます。</FeatureHeading>
        <Feature>
          <FeatureListIconWrapper>
            <TriangleArrowIcon />
          </FeatureListIconWrapper>
          借入可能額の確認
        </Feature>
        <Feature>
          <FeatureListIconWrapper>
            <TriangleArrowIcon />
          </FeatureListIconWrapper>
          エリアごとの物件検索
        </Feature>
        <Feature>
          <FeatureListIconWrapper>
            <TriangleArrowIcon />
          </FeatureListIconWrapper>
          売出し中物件の詳細と収益シミュレーションの閲覧
        </Feature>
      </FeatureList>
      <Button to={Routings.accountRegistration.location}>会員登録する</Button>
    </Wrapper>
  );
};

export default RegistrationGuideArea;
