import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as buyer from '@/state/modules/buyer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { PropertyArea, Property } from 'openapi/kusabi-frontgw-spec';
import theme from '@/styles/theme';
import MansionList from '@/components/organisms/mansionList';
import { Link } from 'gatsby';
import Footer from '@/components/atoms/Footer/BuyerFooter';
import SlashIcon from '@/components/atoms/Icons/SlashIcon';
import ArrowIcon from '@/components/atoms/Icons/ArrowIcon';
import RegistrationGuideArea from '../RegistrationGuideArea';
import Checkbox from '@/components/atoms/Checkbox';
import { Routings } from '@/common/routings';
import * as numberFormatter from '@/utils/numberFormatter';
import Select from '../Select';
import PropertiesNotFoundArea from '../PropertiesNotFoundArea';

const SpBackGroundBase = styled.div`
  width: 100%;
  height: 1150px;
  background: ${theme.colors.spBackground};
  opacity: 1;
`;

const SpCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font: ${theme.fonts.bold_22_33};
  button {
    margin: 10px;
  }
`;

const AreaSearchWrapper = styled.div`
  width: 100%;
  padding: 30px 15px 15px;
`;

const SpTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  margin-bottom: 20px;
`;

const SpTitle = styled.div`
  color: ${theme.colors.background};
  font: ${theme.fonts.bold_22_33};
`;

const MapAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const MapArea = styled.div`
  background: ${theme.colors.white};
  border-radius: 5px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const MapAreaTitle = styled.div`
  color: ${theme.colors.background};
  font: ${theme.fonts.bold_16_23};
  width: 16vw;
`;

const MapAreaTown = styled.div`
  color: ${theme.colors.background};
  font: ${theme.fonts.normal_12_20};
  width: 40vw;
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
  color: ${theme.colors.background};
  background: ${theme.colors.spBackground} 0% 0% no-repeat padding-box;
  .react-tabs__tab--selected {
    div {
      background-color: ${theme.colors.gold};
    }
  }
`;

const CustomTab = styled(Tab)`
  margin-right: 4px;
  cursor: arrow;
  padding: 8px;
  width: 50%;
  text-align: center;
  font: ${theme.fonts.bold_20_22};
  letter-spacing: 0px;
  color: ${theme.colors.background};
  opacity: 1;
`;

const TabBar = styled.div`
  transform: skewX(-30deg);
  height: 1vw;
  width: 40vw;
  background-color: ${theme.colors.tableBorder};
  margin-top: 20px;
`;

const CustomTabContent = styled.div`
  border: none;
  letter-spacing: 0px;
  color: ${theme.colors.background};
  background-color: none;
  padding: 2vw;
`;

const CustomRegistrationGuideArea = styled(RegistrationGuideArea)`
  position: static;
  padding: 41px 28px;
`;

const ContentListCases = styled.label`
  font: ${theme.fonts.bold_25_36};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.gold};
`;

const HigherPropertyListTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: ${theme.fonts.bold_15_27};
  margin-bottom: 3vw;
  padding: 0 2vw;
`;

const LowerPropertyListTop = styled(HigherPropertyListTop)`
  justify-content: flex-end;
`;

const SortLabel = styled.label`
  margin-right: 10px;
`;

const BottomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
  font: ${theme.fonts.bold_16_45};
  letter-spacing: 0px;
  background: ${theme.colors.background};
  color: ${theme.colors.white};
  opacity: 1;
  width: 90vw;
  height: 50px;
`;

const BottomAreaWrapper = styled.div`
  width: 100%;
  color: ${theme.colors.background};
`;

const BottomLabelArea = styled.div`
  width: 90vw;
  padding: 30px 0;
  color: ${theme.colors.blue};
  label {
    font: ${theme.fonts.bold_10_16};
  }
  div {
    font: ${theme.fonts.normal_10_16};
    margin-top: 10px;
  }
`;

const SelectedArea = styled.div`
  white-space: nowrap;
`;

const SelectedAreaWrapper = styled.div`
  flex-direction: column;
`;

const LoanableAmountsArea = styled.div`
  position: static;
  width: 100%;
  height: auto;
  padding: 10px 15px 17px;
  background-color: ${theme.colors.background};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LoanableAmountsAreaLabelWrapper = styled.div`
  width: auto;
  white-space: nowrap;
  margin-right: 30px;
`;

const BalloonBox = styled(Link)`
  position: relative;
  max-width: 100%;
  color: ${theme.colors.background};
  background: ${theme.colors.white};
  border: solid 1px ${theme.colors.gray};
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: ${theme.fonts.bold_12_18};
  height: 70px;
  width: 124px;
  padding: 0 10px;
  margin: 0;
  & em {
    font: inherit;
    color: ${theme.colors.gold};
  }

  &::before {
    content: '';
    position: absolute;
    left: -10%;
    top: 37px;
    margin-left: -5px;
    border: 8px solid transparent;
    border-bottom: 14px solid ${theme.colors.white};
    transform: rotate(-90deg);
    z-index: 0;
  }
`;

const LoanableAmountsCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 16px;
`;

const LoanableAmountsLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_12_22};
  color: ${theme.colors.white};
  padding-right: 10px;
  opacity: 1;
`;

const LoanableAmountsLabel2 = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_30_22};
  color: #d1a827;
  padding-right: 2%;
  margin: 1%;
`;

const LoanableAmountsLabel3 = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_13_22};
  color: ${theme.colors.white};
`;

const LoanableAmount = styled.div<{ isTrialMode?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ isTrialMode }) => (isTrialMode ? 'center' : 'baseline')};
`;

const InductionMessageArea = styled.div`
  width: 87%;
  height: 640px;
  left: 6.5%;
  opacity: 0.85;
  background: ${theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  padding: 3%;
  border-radius: 10px;
`;

const InductionMessage = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.blue};
  opacity: 1;
`;

type Props = {
  isAuthenticatedUser: boolean;
  isTrialMode: boolean;
  isBuyable: boolean;
  tabSelectIndex: number;
  isSort: boolean;
  salesPropertiesState: Property[];
  contractsPropertiesState: Property[];
  contractsSortSelectBox: string[];
  sellerSortSelectBox: string[];
  onChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTab: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onChangeSelectboxItem: (data: string) => void;
  chengeIsSort: (sort: boolean) => void;
  filteringArea: (areaName: PropertyArea) => void;
  selectMap?: string;
  propertiesCount?: number;
  loanableAmount?: number;
  getSpTopAreaHeight: (height: number) => void;
};

const SpBuyer = ({
  isAuthenticatedUser,
  isTrialMode,
  isBuyable,
  tabSelectIndex,
  isSort,
  salesPropertiesState,
  contractsPropertiesState,
  contractsSortSelectBox,
  sellerSortSelectBox,
  onChangeCheckbox,
  onChangeTab,
  onChangeSelectboxItem,
  chengeIsSort,
  filteringArea,
  selectMap,
  propertiesCount,
  loanableAmount,
  getSpTopAreaHeight,
}: Props) => {
  const salesProperties = useSelector(buyer.selectors.getSalesProperties);
  const contractsProperties = useSelector(buyer.selectors.getContractsProperties);
  const createYenFormat = (yen?: number) => {
    if (!yen) {
      return (
        <>
          <LoanableAmountsLabel2>0</LoanableAmountsLabel2>
          <LoanableAmountsLabel3>円</LoanableAmountsLabel3>
        </>
      );
    }
    const pricesArr = numberFormatter.numberToJpYenPrice(yen);
    if (pricesArr.length > 0) {
      if (pricesArr.length === 2) {
        return (
          <>
            {pricesArr[0] && pricesArr[0] !== '0' && (
              <>
                <LoanableAmountsLabel2>{pricesArr[0]}</LoanableAmountsLabel2>
                <LoanableAmountsLabel3>億</LoanableAmountsLabel3>
              </>
            )}
            {pricesArr[1] && pricesArr[1] !== '0' && (
              <>
                <LoanableAmountsLabel2>{pricesArr[1]}</LoanableAmountsLabel2>
                <LoanableAmountsLabel3>万</LoanableAmountsLabel3>
              </>
            )}
            <LoanableAmountsLabel3>円</LoanableAmountsLabel3>
          </>
        );
      }
      return (
        <>
          <LoanableAmountsLabel2>{pricesArr[0]}</LoanableAmountsLabel2>
          <LoanableAmountsLabel3>万円</LoanableAmountsLabel3>
        </>
      );
    }
  };

  useEffect(() => {
    const spTop_h = document.getElementById('SpTopArea')?.clientHeight;
    getSpTopAreaHeight(spTop_h as number);
  }, []);

  return (
    <SpBackGroundBase>
      <SpCenterWrapper id="SpTopArea">
        <AreaSearchWrapper>
          <SpTitleArea>
            <SlashIcon />
            <SpTitle>地域名から探す</SpTitle>
          </SpTitleArea>
          {isTrialMode && (
            <InductionMessageArea>
              <InductionMessage>
                <p>会員登録後に</p>
                <p>エリアごとの物件一覧を</p>
                <p>ご覧いただけます。</p>
              </InductionMessage>
            </InductionMessageArea>
          )}
          <MapAreaWrapper>
            <MapArea onClick={() => filteringArea(PropertyArea.SIX_DISTRICT)}>
              <MapAreaTitle>都心６区</MapAreaTitle>
              <MapAreaTown>千代田区/港区/中央区/新宿区/渋谷区/文京区</MapAreaTown>
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.EAST_CASTLE)}>
              <MapAreaTitle>城東</MapAreaTitle>
              <MapAreaTown>台東区/墨田区/江東区/荒川区/葛飾区/江戸川区/足立区</MapAreaTown>
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.WEST_CASTLE)}>
              <MapAreaTitle>城西</MapAreaTitle>
              <MapAreaTown>中野区/杉並区/練馬区</MapAreaTown>
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.SOUTH_CASTLE)}>
              <MapAreaTitle>城南</MapAreaTitle>
              <MapAreaTown>品川区/目黒区/大田区/世田谷区</MapAreaTown>
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.NORTH_CASTLE)}>
              <MapAreaTitle>城北</MapAreaTitle>
              <MapAreaTown>北区/板橋区/豊島区</MapAreaTown>
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.OUTSIDE_23_WARDS)}>
              <MapAreaTitle>23区外</MapAreaTitle>
              <MapAreaTown />
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.KANAGAWA)}>
              <MapAreaTitle>神奈川</MapAreaTitle>
              <MapAreaTown />
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.SAITAMA)}>
              <MapAreaTitle>埼玉</MapAreaTitle>
              <MapAreaTown />
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
            <MapArea onClick={() => filteringArea(PropertyArea.CHIBA)}>
              <MapAreaTitle>千葉</MapAreaTitle>
              <MapAreaTown />
              <ArrowIcon direction="down" color={isTrialMode ? 'white' : 'gold'} />
            </MapArea>
          </MapAreaWrapper>
        </AreaSearchWrapper>
      </SpCenterWrapper>
      {isTrialMode && (
        <LoanableAmountsArea id="loanableAmountsArea">
          <LoanableAmountsAreaLabelWrapper>
            <LoanableAmountsCount>
              <LoanableAmountsLabel>購入可能物件数</LoanableAmountsLabel>
              <LoanableAmountsLabel2>{propertiesCount}</LoanableAmountsLabel2>
              <LoanableAmountsLabel3>件</LoanableAmountsLabel3>
            </LoanableAmountsCount>
            <LoanableAmount isTrialMode>
              <LoanableAmountsLabel>借入可能金額</LoanableAmountsLabel>
              <LoanableAmountsLabel2>*****</LoanableAmountsLabel2>
              <LoanableAmountsLabel3>万円</LoanableAmountsLabel3>
            </LoanableAmount>
          </LoanableAmountsAreaLabelWrapper>
          <BalloonBox to={Routings.accountRegistration.location}>
            <div>
              <em>会員登録</em>して
              <br />
              借入可能金額を確認する
            </div>
          </BalloonBox>
        </LoanableAmountsArea>
      )}
      {isAuthenticatedUser && (
        <LoanableAmountsArea id="loanableAmountsArea">
          <LoanableAmountsAreaLabelWrapper>
            <LoanableAmountsCount>
              <LoanableAmountsLabel>購入可能物件数</LoanableAmountsLabel>
              <LoanableAmountsLabel2>{propertiesCount}</LoanableAmountsLabel2>
              <LoanableAmountsLabel3>件</LoanableAmountsLabel3>
            </LoanableAmountsCount>
            <LoanableAmount>
              <LoanableAmountsLabel>借入可能金額</LoanableAmountsLabel>
              <LoanableAmountsLabel2>{createYenFormat(loanableAmount)}</LoanableAmountsLabel2>
            </LoanableAmount>
          </LoanableAmountsAreaLabelWrapper>
        </LoanableAmountsArea>
      )}
      <CustomTabs selectedIndex={tabSelectIndex}>
        <SpCenterWrapper>
          <TabList
            id="tabs"
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyleType: 'none',
              margin: '0px',
              padding: '2vw',
            }}
          >
            <CustomTab onClick={onChangeTab}>
              売出し中物件
              <TabBar />
            </CustomTab>
            <CustomTab onClick={onChangeTab}>
              成約済み物件
              <TabBar />
            </CustomTab>
          </TabList>
        </SpCenterWrapper>
        <TabPanel>
          {!isAuthenticatedUser && !isTrialMode ? (
            <CustomTabContent>
              <CustomRegistrationGuideArea />
            </CustomTabContent>
          ) : (
            <>
              <CustomTabContent>
                <HigherPropertyListTop>
                  <SelectedAreaWrapper>
                    <SelectedArea>{selectMap}</SelectedArea>
                    <ContentListCases>
                      {salesPropertiesState ? salesProperties.salesPropertiesCount : 0} 件
                    </ContentListCases>
                  </SelectedAreaWrapper>
                  <HigherPropertyListTop style={{ marginBottom: selectMap ? '-8%' : '0' }}>
                    <Checkbox
                      id="displayAllProperty"
                      label="購入可能物件*のみ表示"
                      disabled={!isAuthenticatedUser}
                      checked={isBuyable}
                      onChange={(e) => onChangeCheckbox(e)}
                      isNoBorder={true}
                      isDisable={!isAuthenticatedUser}
                    />
                  </HigherPropertyListTop>
                </HigherPropertyListTop>
                <LowerPropertyListTop>
                  <SortLabel htmlFor="sort">並び替え</SortLabel>
                  <Select
                    id="sort"
                    disabled={isTrialMode}
                    onChange={(ev) => {
                      if (ev) {
                        onChangeSelectboxItem(ev.target.value);
                      }
                    }}
                  >
                    {sellerSortSelectBox.map((sort, i) => (
                      <option key={i}>{sort}</option>
                    ))}
                  </Select>
                </LowerPropertyListTop>
              </CustomTabContent>
              {salesPropertiesState && salesProperties.salesPropertiesCount !== 0 ? (
                <MansionList
                  properties={salesPropertiesState}
                  isRegistered={isAuthenticatedUser}
                  isOnSale={true}
                  isDisablePager={false}
                  spMode={true}
                  isSort={isSort}
                  chengeIsSort={chengeIsSort}
                />
              ) : (
                <div style={{ padding: '5%' }}>
                  {isTrialMode && <CustomRegistrationGuideArea />}
                  {isAuthenticatedUser && <PropertiesNotFoundArea />}
                </div>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel>
          <CustomTabContent>
            <HigherPropertyListTop>
              <SelectedAreaWrapper>
                <SelectedArea>{selectMap}</SelectedArea>
                <ContentListCases>
                  {contractsPropertiesState ? contractsProperties.contractsPropertiesCount : 0} 件
                </ContentListCases>
              </SelectedAreaWrapper>
            </HigherPropertyListTop>
            <LowerPropertyListTop>
              <SortLabel htmlFor="sort">並び替え</SortLabel>
              <Select
                id="sort"
                onChange={(ev) => {
                  if (ev) {
                    onChangeSelectboxItem(ev.target.value);
                  }
                }}
              >
                {contractsSortSelectBox.map((sort, i) => (
                  <option key={i}>{sort}</option>
                ))}
              </Select>
            </LowerPropertyListTop>
          </CustomTabContent>
          <MansionList
            properties={contractsPropertiesState}
            isRegistered={isAuthenticatedUser}
            isOnSale={false}
            isDisablePager={false}
            spMode={true}
            isSort={isSort}
            chengeIsSort={chengeIsSort}
          />
        </TabPanel>
        <BottomAreaWrapper>
          <SpCenterWrapper>
            {!isTrialMode && (
              <BottomButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                他の地域から探す
              </BottomButton>
            )}
            <BottomLabelArea>
              <label>注意事項</label>
              <div>
                ※購入可能物件は年齢、収入、借入金額などをもとに算出したもので、購入できることを保証するものではありません。
              </div>
            </BottomLabelArea>
          </SpCenterWrapper>
          <Footer showBanner />
        </BottomAreaWrapper>
      </CustomTabs>
    </SpBackGroundBase>
  );
};

export default SpBuyer;
