import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import MansionList from '@/components/organisms/mansionList';
import * as buyer from '@/state/modules/buyer';
import * as member from '@/state/modules/member';
import * as loanableAmounts from '@/state/modules/loanableAmounts';
import flexCenter from '@/styles/mixins/flexCenter';
import { sortBy } from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BuyerHeader from './Header';
import { isAuthenticated } from '@/utils/authentication';
import { Property, PropertyArea, PropertySort } from 'openapi/kusabi-frontgw-spec';
import theme from '@/styles/theme';
import { GetContractPropertiesParam, GetSalePropertiesParam } from '@/models/request/buyer';
import AreaMap from './AreaMap';
import MediaQuery from 'react-responsive';
import SpBuyerTop from '@/components/organisms/Buyer/SpBuyerTop';
import { GetLoanableAmountsStateParam } from '@/models/request/loanableAmounts';
import { birthdayToAge } from '@/utils/numberFormatter';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Breakpoints } from '@/constants/constants';
import * as numberFormatter from '@/utils/numberFormatter';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import { Routings } from '@/common/routings';
import RegistrationGuideArea from './RegistrationGuideArea';
import Checkbox from '@/components/atoms/Checkbox';
import Select from './Select';
import { PropertySortSelectBox } from '@/common/validation';
import PropertiesNotFoundArea from './PropertiesNotFoundArea';

const CenterWrapper = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 90vw;
  width: 100%;
  ${flexCenter}
  flex-wrap: wrap;
  background: ${theme.colors.tableHeaderBackground} 0% 0% no-repeat padding-box;
  button {
    margin: 10px;
  }
  overflow: hidden;
`;

const BottomLeftBackGround = styled.div`
  position: absolute;
  left: -38.7vw;
  top: 0;
  z-index: -5;
  background-color: ${theme.colors.spBackground};
  /* 四角形を傾ける */
  transform: skewX(-30deg);
  width: 62vw;
  height: 82vw;
`;

const TabContent = styled.div`
  padding-top: 1%;
  display: flex;
  justify-content: space-between;
  border: solid #000000;
  border-width: 1px 1px 0 1px;
  p {
    margin-bottom: 1%;
  }
`;

const BackGroundBaseLeft = styled.div`
  background-color: ${theme.colors.background};
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  width: 50%;
  height: 80vw;
`;

const BackGroundBaseRight = styled.div`
  position: absolute;
  background: ${theme.colors.tableHeaderBackground} 0% 0% no-repeat padding-box;
  left: 0;
  top: 0;
  z-index: -4;
  width: 100%;
  height: 90vw;
`;

const SpBackGroundBase = styled.div<{
  spTopHeaderHeight: string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -4;
  width: 100%;
  ${({ spTopHeaderHeight }) =>
    css`
      height: ${spTopHeaderHeight};
    `}
`;

const MapBackGround = styled.div`
  background-color: ${theme.colors.spBackground};
  content: '';
  position: absolute;
  left: 11vw;
  top: 0;
  /* 四角形を傾ける */
  transform: skewX(-30deg);
  z-index: 0;
  width: 62vw;
  height: 90vw;
  overflow: hidden;
`;

const RightBackGround = styled.div`
  content: '';
  position: absolute;
  right: -60vw;
  bottom: 0;
  z-index: -2;
  background-color: ${theme.colors.blue};
  /* 四角形を傾ける */
  transform: skewX(-30deg);
  width: 60vw;
  height: 82vw;
`;

const TabsArea = styled.div`
  width: 90vw;
`;

const RightArea = styled.div`
  position: absolute;
  top: 30vw;
  right: 13vw;
  transform: rotate(30deg);
  width: 7vw;
  height: 20vw;
`;

const RightAreaLabelTitle = styled.div`
  transform: rotate(-30deg);
  text-align: left;
  font: ${theme.fonts.bold_15_22};
  letter-spacing: 0px;
  padding-bottom: 20px;
  color: ${theme.colors.background};
`;

const RightAreaLabel = styled.div`
  transform: rotate(-30deg);
  text-align: left;
  font: ${theme.fonts.bold_15_22};
  letter-spacing: 0px;
  color: ${theme.colors.background};
  padding: 10px;
  white-space: nowrap;
`;

const CustomTabs = styled(Tabs)`
  padding: 3vw 5vw 5vw 5vw;
  .react-tabs__tab--selected {
    div {
      background: ${theme.colors.gold} 0% 0% no-repeat padding-box;
    }
  }
`;

const CustomTab = styled(Tab)`
  margin-right: 4px;
  cursor: arrow;
  padding: 8px;
  width: 50%;
  text-align: center;
  font: ${theme.fonts.bold_20_22};
  letter-spacing: 0px;
  color: ${theme.colors.background};
  opacity: 1;
`;

const TabBar = styled.div`
  transform: skewX(-30deg);
  height: 0.7vh;
  margin: 0 auto;
  background-color: ${theme.colors.tableBorder};
  margin-top: 2vh;
`;

const CustomTabContent = styled(TabContent)`
  border: none;
  text-align: left;
  font: ${theme.fonts.bold_15_22};
  letter-spacing: 0px;
  color: ${theme.colors.background};
  background-color: none;
  opacity: 1;
`;

const BottomCenterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 10px;
  }
`;

const CustomRegistrationGuideArea = styled(RegistrationGuideArea)`
  position: static;
`;

const NotesAreaLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.normal_10_16};
  letter-spacing: 0px;
  color: ${theme.colors.blue};
  opacity: 1;
  div {
    font: ${theme.fonts.bold_10_16};
    margin-bottom: 1vh;
  }
`;

const BottomWrapper = styled.div`
  width: 60%;
  height: 300px;
`;

const ButtonCenterWrapper = styled.div`
  ${flexCenter}
`;

const TabContentLeft = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentListCases = styled.div`
  font: ${theme.fonts.bold_25_36};
  color: ${theme.colors.gold};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  margin-right: 5%;
  white-space: nowrap;
`;

const SortLabel = styled.label`
  padding-right: 1vw;
  font: ${theme.fonts.bold_16_27};
  width: 100px;
  ${flexCenter}
`;

const BottomButton = styled.button`
  border: 1px solid ${theme.colors.white};
  border-radius: 4px;
  text-align: center;
  font: ${theme.fonts.bold_16_45};
  letter-spacing: 0px;
  color: ${theme.colors.white};
  background: ${theme.colors.background} 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 354px;
  height: 50px;
`;

const contractsSortSelectBox = Object.entries(PropertySortSelectBox).map((obj) => {
  return obj[1];
});

const sellerSortSelectBox = Object.entries(PropertySort).map((obj) => {
  return obj[1];
});

const SelectedArea = styled.div`
  white-space: nowrap;
  margin-right: 5%;
`;

const LoanableAmountsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${theme.colors.white};
  margin: 0;
  padding: 2% 0;
  border: 1px solid ${theme.colors.gold};
  border-radius: 10px;
  opacity: 1;
`;

const CenterBorder = styled.div`
  width: 1px;
  height: 50px;
  background: ${theme.colors.inputBorder};
  margin-left: 5%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

const LoanableAmountsLabel = styled.label<{ isTrialMode?: boolean }>`
  text-align: left;
  font: ${theme.fonts.bold_22_33_vw};
  color: ${theme.colors.blue};
  margin-left: 7%;
  ${({ isTrialMode }) =>
    !isTrialMode &&
    ` &:first-of-type {
    margin-left: 13.5%;
  } `}
  span {
    font: ${theme.fonts.normal_12_22_vw};
  }
`;

const LoanableAmountsNumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: 2%;
  white-space: nowrap;
  &:last-of-type {
    margin-right: 2%;
  }
`;

const LoanableAmountsNumberLabel = styled.label`
  text-align: left;
  margin-left: 3%;
  font: ${theme.fonts.black_40_45_vw};
  color: ${theme.colors.gold};
  white-space: nowrap;
`;

const LoanableAmountsNumberUnitLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.black_16_45_vw};
  color: ${theme.colors.gold};
  &:last-of-type {
    margin-right: 1%;
  }
`;

const InductionText = styled.p`
  font: ${theme.fonts.normal_12_22_vw};
  color: ${theme.colors.black};
  margin-left: 1%;
`;

const Link = styled.a`
  font: ${theme.fonts.bold_12_22_vw};
  color: ${theme.colors.gold};
  text-decoration: none;
`;

const InductionMessageArea = styled.div`
  width: 52%;
  height: 150px;
  top: 48vh;
  left: 25vw;
  opacity: 0.85;
  background: ${theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  z-index: 9999;
`;

const InductionMessage = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.blue};
  font: ${theme.fonts.bold_22_33_vw};
  opacity: 1;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

type Props = {
  propertyArea?: PropertyArea;
  isOpenTrialModal?: boolean;
};

const MAX_LIMIT = 100000;

const Buyer = ({ propertyArea, isOpenTrialModal }: Props) => {
  const dispatch = useDispatch();
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
  const [isTrialMode, setIsTrialMode] = useState(false);
  const salesProperties = useSelector(buyer.selectors.getSalesProperties);
  const [salesPropertiesState, setSalesPropertiesState] = useState(salesProperties.salesProperties);
  const contractsProperties = useSelector(buyer.selectors.getContractsProperties);
  const [contractsPropertiesState, setContractsPropertiesState] = useState(contractsProperties.contractsProperties);
  const [selectBoxLabel, setSelectBoxLabel] = useState<PropertySortSelectBox>(PropertySortSelectBox.NEW_ARRIVAL_DESC);
  const [isOpenOnSaleTab, setIsOpenOnSaleTab] = useState(true);
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const [param, setParam] = useState<GetLoanableAmountsStateParam>();
  const loginMember = useSelector(member.selectors.getMember);
  const getLoanableAmounts = useSelector(loanableAmounts.selectors.getLoanableAmounts);
  const [showAreaCities, setShowAreaCities] = useState<{ areaName: string; areaCities: (string | undefined)[] }>();
  const areaMappings = useSelector(buyer.selectors.getAreaMappings);
  const [tabSelectIndex, setTabSelectIndex] = useState(0);
  const [isBuyable, setIsBuyable] = useState(true);
  const [isSort, setIsSort] = useState(false);
  const [selectMap, setSelectMap] = useState('');
  const [spTopAreaHeight, setSpTopAreaHeight] = useState(0);
  const [spHeaderHeight, setSpHeaderHeight] = useState(0);
  const [spTopHeaderHeight, setSpTopHeaderHeight] = useState('');

  useEffect(() => {
    const getAuth = async () => {
      const result = await isAuthenticated();
      setIsAuthenticatedUser(result);
      if (result) {
        dispatch(member.operations.getMember());
      } else {
        setTabSelectIndex(1);
        setIsOpenOnSaleTab(false);
      }
    };
    getAuth();
    if (propertyArea) {
      filteringArea(propertyArea);
    } else {
      getContractsProperties({ sort: PropertySort.NEW_ARRIVAL_DESC, limit: MAX_LIMIT });
    }
    dispatch(buyer.operations.getAreaMappings());
    setIsSort(false);
  }, []);

  // selectorで売出し中物件取得時に、state管理＆並び替えを初期値で実施
  useEffect(() => {
    setSalesPropertiesState(salesProperties.salesProperties);
    onChangeSelectboxItem(selectBoxLabel);
  }, [salesProperties]);

  // selectorで成約済み物件取得時に、state管理＆並び替えを初期値で実施
  useEffect(() => {
    setContractsPropertiesState(sortBy(contractsProperties.contractsProperties, 'contractDate').reverse());
    onChangeSelectboxItem(selectBoxLabel);
  }, [contractsProperties]);

  // 売出し中物件を取得
  const getSalesProperties = (getSalePropertiesParam: GetSalePropertiesParam) => {
    dispatch(buyer.operations.getSaleProperties(getSalePropertiesParam));
  };

  // 成約済み物件を取得
  const getContractsProperties = (getContractPropertiesParam: GetContractPropertiesParam) => {
    dispatch(buyer.operations.getContractProperties(getContractPropertiesParam));
  };

  /**
   * 並び替えセレクトボックス選択時の処理
   * @param data
   */
  const onChangeSelectboxItem = (data: string) => {
    if (data != selectBoxLabel) {
      setSelectBoxLabel(data as PropertySortSelectBox);
      const sortData = data === PropertySortSelectBox.NEW_ARRIVAL_DESC ? PropertySort.NEW_ARRIVAL_DESC : data;
      setIsSort(true);
      isOpenOnSaleTab
        ? setSalesPropertiesState((prevState: Property[]) => {
            return sortProperties(sortData, prevState);
          })
        : setContractsPropertiesState((prevState: Property[]) => {
            return sortProperties(sortData, prevState);
          });
    }
  };

  /**
   * 並び替え実処理
   * @param sortCondition
   * @param prevState
   * @return 整列後の物件一覧
   */
  const sortProperties = (sortCondition: string, prevState: Property[]) => {
    switch (sortCondition) {
      case PropertySort.NEW_ARRIVAL_DESC: {
        return isOpenOnSaleTab
          ? sortBy(prevState, 'firstPublishedAt').reverse()
          : sortBy(prevState, 'contractDate').reverse();
      }
      case PropertySort.SALES_PRICE_DESC: {
        return sortBy(prevState, 'salesPrice').reverse();
      }
      case PropertySort.SALES_PRICE_ASC: {
        return sortBy(prevState, 'salesPrice');
      }
      case PropertySort.BUILT_DATE_DESC: {
        return sortBy(prevState, 'builtDate');
      }
      case PropertySort.BUILT_DATE_ASC: {
        return sortBy(prevState, 'builtDate').reverse();
      }
      case PropertySort.WALK_MINUTE_DESC: {
        return sortBy(prevState, 'walkMinute').reverse();
      }
      case PropertySort.WALK_MINUTE_ASC: {
        return sortBy(prevState, 'walkMinute');
      }
      default: {
        return prevState;
      }
    }
  };

  // お試し物件一覧の売出し中物件タブを選択時は常に並び替え条件を「新着順」に設定
  const onChangeTab = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (e.currentTarget.ariaSelected != 'true') {
      onChangeSelectboxItem(PropertySortSelectBox.NEW_ARRIVAL_DESC as string);
      if (isOpenOnSaleTab) {
        if (!isAuthenticatedUser && isTrialMode) {
          return;
        }
        setIsOpenOnSaleTab(false);
        setTabSelectIndex(1);
      } else {
        setIsOpenOnSaleTab(true);
        setTabSelectIndex(0);
      }
    }
  };

  const trialModeSwitch = (isTrialMode: boolean) => {
    setIsTrialMode(isTrialMode);
    dispatch(buyer.operations.changeTrialMode(isTrialMode));
  };

  // お試し入力の情報を設定
  const setLoanableAmountsParam = (data: GetLoanableAmountsStateParam) => {
    setParam(data);
  };

  useEffect(() => {
    dispatch(loanableAmounts.operations.getLoanableAmounts(param as GetLoanableAmountsStateParam));
  }, [param]);

  useEffect(() => {
    setMaxLoanAmount(getLoanableAmounts.loanableAmount as number);
  }, [getLoanableAmounts]);

  // 売出し中物件取得
  useEffect(() => {
    if (isAuthenticatedUser || isTrialMode) {
      getSalesProperties({
        maxLoanAmount: maxLoanAmount,
        isBuyable: isBuyable,
        sort:
          selectBoxLabel === PropertySortSelectBox.NEW_ARRIVAL_DESC
            ? PropertySort.NEW_ARRIVAL_DESC
            : (selectBoxLabel as PropertySort),
        area: selectMap != '' ? (selectMap as PropertyArea) : undefined,
        limit: MAX_LIMIT,
      });
    }
  }, [maxLoanAmount, isBuyable]);

  useEffect(() => {
    if (isAuthenticatedUser) {
      const getLoanableAmountsParam: GetLoanableAmountsStateParam = {
        annualIncome: loginMember.annualIncome,
        totalLoanAmount: loginMember.totalLoanAmount,
        age: birthdayToAge(new Date(loginMember.birthday)),
      };
      setParam(getLoanableAmountsParam);
    }
  }, [loginMember]);

  const showAreaData = (areaName: PropertyArea) => {
    const cities = [];
    for (const i in areaMappings.entities.areaMappings) {
      if (areaMappings.entities.areaMappings[Number(i)].areaName === areaName) {
        cities.push(areaMappings.entities.areaMappings[Number(i)].cityName);
      }
    }
    setShowAreaCities({ areaName: areaName, areaCities: cities });
  };

  const filteringArea = (areaName: PropertyArea) => {
    if (isTrialMode) return;
    setSelectMap(areaName);
    getContractsProperties({ area: areaName, sort: PropertySort.NEW_ARRIVAL_DESC });
    if (isAuthenticatedUser) {
      getSalesProperties({ maxLoanAmount: maxLoanAmount, isBuyable: isBuyable, area: areaName, limit: MAX_LIMIT });
      const loanableAmountsArea = document.getElementById('loanableAmountsArea');
      loanableAmountsArea?.scrollIntoView({ behavior: 'smooth' });
    } else {
      const tabs = document.getElementById('tabs');
      tabs?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const mapMouseOut = () => {
    setShowAreaCities({ areaName: '', areaCities: [] });
  };

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsBuyable(e.target.checked);
  };

  useEffect(() => {
    if (isTrialMode) {
      setTabSelectIndex(0);
      setSelectMap('');
      const loanableAmountsArea = document.getElementById('loanableAmountsArea');
      loanableAmountsArea?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isTrialMode]);

  const chengeIsSort = (sort: boolean) => {
    setIsSort(sort);
  };

  const createYenFormat = (yen?: number) => {
    if (!yen) {
      return (
        <>
          <LoanableAmountsNumberLabel>0</LoanableAmountsNumberLabel>
          <LoanableAmountsNumberUnitLabel>円</LoanableAmountsNumberUnitLabel>
        </>
      );
    }
    const pricesArr = numberFormatter.numberToJpYenPrice(yen);
    if (pricesArr.length > 0) {
      if (pricesArr.length === 2) {
        return (
          <>
            {pricesArr[0] && pricesArr[0] !== '0' && (
              <>
                <LoanableAmountsNumberLabel>{pricesArr[0]}</LoanableAmountsNumberLabel>
                <LoanableAmountsNumberUnitLabel>億</LoanableAmountsNumberUnitLabel>
              </>
            )}
            {pricesArr[1] && pricesArr[1] !== '0' && (
              <>
                <LoanableAmountsNumberLabel>{pricesArr[1]}</LoanableAmountsNumberLabel>
                <LoanableAmountsNumberUnitLabel>万</LoanableAmountsNumberUnitLabel>
              </>
            )}
            <LoanableAmountsNumberUnitLabel>円</LoanableAmountsNumberUnitLabel>
          </>
        );
      }
      return (
        <>
          <LoanableAmountsNumberLabel>{pricesArr[0]}</LoanableAmountsNumberLabel>
          <LoanableAmountsNumberUnitLabel>万円</LoanableAmountsNumberUnitLabel>
        </>
      );
    }
  };

  const getSpTopAreaHeight = (height: number) => {
    setSpTopAreaHeight(height);
  };

  const getSpHeaderHeight = (height: number) => {
    setSpHeaderHeight(height);
  };

  useEffect(() => {
    setSpTopHeaderHeight(spTopAreaHeight + spHeaderHeight + 'px');
  }, [spTopAreaHeight, spHeaderHeight]);

  return (
    <>
      <MediaQuery query="(max-width: 900px)">
        {/* スマホデザイン */}
        <SpBackGroundBase spTopHeaderHeight={spTopHeaderHeight}>
          <BuyerHeader
            isAuthenticatedUser={isAuthenticatedUser}
            isTrialMode={isTrialMode}
            getLoanableAmounts={getLoanableAmounts}
            trialModeSwitch={trialModeSwitch}
            setLoanableAmountsParam={setLoanableAmountsParam}
            filteringArea={filteringArea}
            getSpHeaderHeight={getSpHeaderHeight}
            isOpenTrialModal={isOpenTrialModal}
          />
          <SpBuyerTop
            isAuthenticatedUser={isAuthenticatedUser}
            isTrialMode={isTrialMode}
            isBuyable={isBuyable}
            tabSelectIndex={tabSelectIndex}
            salesPropertiesState={salesPropertiesState}
            contractsPropertiesState={contractsPropertiesState}
            contractsSortSelectBox={contractsSortSelectBox}
            sellerSortSelectBox={sellerSortSelectBox}
            onChangeCheckbox={onChangeCheckbox}
            onChangeTab={onChangeTab}
            onChangeSelectboxItem={onChangeSelectboxItem}
            isSort={isSort}
            chengeIsSort={chengeIsSort}
            filteringArea={filteringArea}
            selectMap={selectMap}
            propertiesCount={getLoanableAmounts.propertiesCount}
            loanableAmount={getLoanableAmounts.loanableAmount}
            getSpTopAreaHeight={getSpTopAreaHeight}
          />
        </SpBackGroundBase>
      </MediaQuery>
      <MediaQuery query="(min-width: 901px)">
        {/* PCデザイン */}
        <BackGroundBaseRight>
          <BackGroundBaseLeft />
          <RightArea>
            <RightAreaLabelTitle>{showAreaCities?.areaName}</RightAreaLabelTitle>
            {showAreaCities?.areaCities.map((city, i) => (
              <RightAreaLabel key={i}>{city}</RightAreaLabel>
            ))}
          </RightArea>
          {isTrialMode && (
            <InductionMessageArea>
              <InductionMessage>
                <p>会員登録後にエリアごとの物件一覧をご覧いただけます。</p>
              </InductionMessage>
            </InductionMessageArea>
          )}
          <MapBackGround>
            <AreaMap
              showAreaData={showAreaData}
              filteringArea={filteringArea}
              mapMouseOut={mapMouseOut}
              isTrialMode={isTrialMode}
            />
          </MapBackGround>
          <BuyerHeader
            isAuthenticatedUser={isAuthenticatedUser}
            isTrialMode={isTrialMode}
            getLoanableAmounts={getLoanableAmounts}
            trialModeSwitch={trialModeSwitch}
            setLoanableAmountsParam={setLoanableAmountsParam}
          />
          <CenterWrapper id="CenterWrapper">
            <div>
              {isAuthenticatedUser && (
                <LoanableAmountsArea id="loanableAmountsArea">
                  <LoanableAmountsLabel>
                    購入可能物件<span>※</span>数
                  </LoanableAmountsLabel>
                  <LoanableAmountsNumberLabel>
                    {getLoanableAmounts.propertiesCount ? getLoanableAmounts.propertiesCount : 0}
                    <LoanableAmountsNumberUnitLabel>件</LoanableAmountsNumberUnitLabel>
                  </LoanableAmountsNumberLabel>
                  <CenterBorder />
                  <LoanableAmountsLabel>借入可能金額</LoanableAmountsLabel>
                  <LoanableAmountsNumberLabel>
                    {createYenFormat(getLoanableAmounts.loanableAmount)}
                  </LoanableAmountsNumberLabel>
                </LoanableAmountsArea>
              )}
              {!isAuthenticatedUser && isTrialMode && (
                <LoanableAmountsArea id="loanableAmountsArea">
                  <LoanableAmountsLabel isTrialMode>
                    購入可能物件<span>※</span>数
                  </LoanableAmountsLabel>
                  <LoanableAmountsNumberWrapper>
                    <LoanableAmountsNumberLabel>
                      {getLoanableAmounts.propertiesCount ? getLoanableAmounts.propertiesCount : 0}
                    </LoanableAmountsNumberLabel>
                    <LoanableAmountsNumberUnitLabel>件</LoanableAmountsNumberUnitLabel>
                  </LoanableAmountsNumberWrapper>
                  <CenterBorder />
                  <LoanableAmountsLabel>借入可能金額</LoanableAmountsLabel>
                  <LoanableAmountsNumberWrapper style={{ alignItems: 'center' }}>
                    <LoanableAmountsNumberLabel style={{ font: `${theme.fonts.black_40_1_vw}`, marginTop: '10%' }}>
                      *****
                    </LoanableAmountsNumberLabel>
                    <LoanableAmountsNumberUnitLabel style={{ marginTop: '10%' }}>万円</LoanableAmountsNumberUnitLabel>
                  </LoanableAmountsNumberWrapper>
                  <InfoIcon color="white" />
                  <InductionText>
                    借入可能金額をみるには<Link href={Routings.accountRegistration.location}>会員登録が必要</Link>です。
                  </InductionText>
                </LoanableAmountsArea>
              )}
              <TabsArea id="tabs">
                <CustomTabs selectedIndex={tabSelectIndex}>
                  <TabList
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      listStyleType: 'none',
                      marginLeft: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    <CustomTab onClick={(e) => onChangeTab(e)}>
                      売出し中物件
                      <TabBar />
                    </CustomTab>
                    <CustomTab onClick={(e) => onChangeTab(e)}>
                      成約済み物件
                      <TabBar />
                    </CustomTab>
                  </TabList>
                  <TabPanel id="TabPanel">
                    {!isAuthenticatedUser && !isTrialMode ? (
                      <CustomTabContent>
                        <CustomRegistrationGuideArea />
                      </CustomTabContent>
                    ) : (
                      <>
                        <CustomTabContent>
                          <TabContentLeft>
                            {selectMap && <SelectedArea>{selectMap}</SelectedArea>}
                            <ContentListCases>
                              {salesPropertiesState ? salesProperties.salesPropertiesCount : 0} 件
                            </ContentListCases>
                            <Checkbox
                              id="displayAllProperty"
                              label="購入可能物件*のみ表示"
                              disabled={!isAuthenticatedUser}
                              checked={isBuyable}
                              onChange={(e) => onChangeCheckbox(e)}
                              isDisable={!isAuthenticatedUser}
                            />
                          </TabContentLeft>
                          <div style={{ display: 'flex' }}>
                            <SortLabel htmlFor="sort">並び替え</SortLabel>
                            <Select
                              id="sort"
                              disabled={isTrialMode}
                              onChange={(ev) => {
                                if (ev) {
                                  onChangeSelectboxItem(ev.target.value);
                                }
                              }}
                            >
                              {sellerSortSelectBox.map((sort, i) => (
                                <option key={i}>{sort}</option>
                              ))}
                            </Select>
                          </div>
                        </CustomTabContent>
                        {salesPropertiesState && salesProperties.salesPropertiesCount !== 0 ? (
                          <MansionList
                            properties={salesPropertiesState}
                            isRegistered={isAuthenticatedUser}
                            isOnSale={true}
                            isDisablePager={false}
                            spMode={false}
                            isSort={isSort}
                            chengeIsSort={chengeIsSort}
                          />
                        ) : (
                          <div style={{ padding: '5%' }}>
                            {isTrialMode && <CustomRegistrationGuideArea />}
                            {isAuthenticatedUser && <PropertiesNotFoundArea />}
                          </div>
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <CustomTabContent>
                      <TabContentLeft>
                        {selectMap && <SelectedArea>{selectMap}</SelectedArea>}
                        <ContentListCases>
                          {contractsPropertiesState ? contractsProperties.contractsPropertiesCount : 0} 件
                        </ContentListCases>
                      </TabContentLeft>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SortLabel htmlFor="sort">並び替え</SortLabel>
                        <Select
                          id="sort"
                          onChange={(ev) => {
                            if (ev) {
                              onChangeSelectboxItem(ev.target.value);
                            }
                          }}
                        >
                          {contractsSortSelectBox.map((sort, i) => (
                            <option key={i}>{sort}</option>
                          ))}
                        </Select>
                      </div>
                    </CustomTabContent>
                    <MansionList
                      properties={contractsPropertiesState}
                      isRegistered={isAuthenticatedUser}
                      isOnSale={false}
                      isDisablePager={false}
                      spMode={false}
                      isSort={isSort}
                      chengeIsSort={chengeIsSort}
                    />
                  </TabPanel>
                </CustomTabs>
              </TabsArea>
              <ButtonCenterWrapper>
                <BottomWrapper>
                  {!isTrialMode && (
                    <BottomCenterWrapper style={{ marginBottom: '10%' }}>
                      <BottomButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        他の地域から探す
                      </BottomButton>
                    </BottomCenterWrapper>
                  )}
                  <ButtonCenterWrapper>
                    <NotesAreaLabel>
                      <div>注意事項</div>
                      ※購入可能物件は年齢、収入、借入金額などをもとに算出したもので、購入できることを保証するものではありません。
                    </NotesAreaLabel>
                  </ButtonCenterWrapper>
                  <BottomLeftBackGround />
                </BottomWrapper>
              </ButtonCenterWrapper>
            </div>
            <FooterWrapper>
              <BuyerFooter showBanner isBuyerTop />
            </FooterWrapper>
            <RightBackGround />
          </CenterWrapper>
        </BackGroundBaseRight>
      </MediaQuery>
    </>
  );
};

export default Buyer;
