import theme from '@/styles/theme';
import React, { memo } from 'react';

const colors = theme.colors;

type Props = {
  color?: keyof typeof colors;
};

const ChevronRight = ({ color = 'gold' }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={colors[color]}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </svg>
  );
};

export default memo(ChevronRight);
