import { css } from 'styled-components';

const flexCenter = css`
  display: flex;

  align-items: center;
  justify-content: center;
`;

export default flexCenter;
