import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';

export const schema = yup.object({
  annualIncome: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .min(1, YupErrorMessage.number.min)
    .max(999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('年収'),
  totalLoanAmount: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .min(0, YupErrorMessage.number.min)
    .max(999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('総借入金額'),
  age: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .min(0, YupErrorMessage.number.min)
    .max(100, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('年齢'),
});

export type FormState = yup.InferType<typeof schema>;
