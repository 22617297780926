import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Routings } from '@/common/routings';
import theme from '@/styles/theme';
import { Breakpoints } from '@/constants/constants';

const Wrapper = styled.div`
  width: 86%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 50px 30px;
  background: ${theme.colors.white};
  border-radius: 10px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 40px 30px;
  }
`;

const GuideMessage = styled.p`
  font: ${theme.fonts.normal_16_32};
  color: ${theme.colors.text};
  margin-bottom: 62px;
`;

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 44px;
  border-radius: 4px;
  background: ${theme.colors.blue};
  text-decoration: none;
  color: ${theme.colors.white};
  font: ${theme.fonts.bold_16_32};
`;

type Props = {
  className?: string;
};

const PropertiesNotFoundArea = (props: Props) => {
  const { className } = props;
  return (
    <Wrapper className={className}>
      <GuideMessage>
        申し訳ございませんが、現時点での購入可能物件は見つかりませんでした。
        <br />
        条件によっては、ご購入可能な物件が見つかる可能性がございます。
        <br />
        詳しくは、以下よりお問合せください。
      </GuideMessage>
      <Button to={Routings.buyerInquiry.location}>お問合せはこちら</Button>
    </Wrapper>
  );
};

export default PropertiesNotFoundArea;
