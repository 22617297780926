import React from 'react';
import Buyer from '@/components/organisms/Buyer';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import { PropertyArea } from 'openapi/kusabi-frontgw-spec';

type Props = {
  location: {
    state: {
      propertyArea?: PropertyArea;
      isOpenTrialModal?: boolean;
    };
  };
};

const BuyerPage: React.VFC<Props> = (props: Props) => {
  const propertyArea = props.location.state?.propertyArea && props.location.state.propertyArea;
  const isOpenTrialModal = props.location.state?.isOpenTrialModal;
  // isOpenTrialModalをリセット
  const isBrowser = () => typeof window !== 'undefined';
  isBrowser() && window.history.pushState({}, '');
  return <Buyer propertyArea={propertyArea} isOpenTrialModal={isOpenTrialModal} />;
};

export default withInitializeApp(BuyerPage);
