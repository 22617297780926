import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import TrialFormModal from '@/components/organisms/TrialFormModal';
import { LoanableAmountsProperty, PropertyArea } from 'openapi/kusabi-frontgw-spec';
import { Routings } from '@/common/routings';
import theme from '@/styles/theme';
import { Breakpoints } from '@/constants/constants';
import { GetLoanableAmountsStateParam } from '@/models/request/loanableAmounts';
import Header from '@/components/atoms/Header/BuyerHeader';
import * as numberFormatter from '@/utils/numberFormatter';
import SlashIcon from '@/components/atoms/Icons/SlashIcon';
import { toVw } from '@/common/utilities';
import { useMediaQuery } from 'react-responsive';

type Props = {
  isAuthenticatedUser: boolean;
  isTrialMode: boolean;
  getLoanableAmounts: LoanableAmountsProperty;
  trialModeSwitch: (isTrialMode: boolean) => void;
  setLoanableAmountsParam: (data: GetLoanableAmountsStateParam) => void;
  filteringArea?: (areaName: PropertyArea) => void;
  getSpHeaderHeight?: (height: number) => void;
  isOpenTrialModal?: boolean;
};

const CatchCopyArea = styled.div`
  position: absolute;
  z-index: 0;
  top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toVw(30)};
  width: 100%;
  height: ${toVw(70)};
  padding-left: ${toVw(48)};
  opacity: 1;
  background-color: ${theme.colors.white};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    position: static;
    flex-direction: column;
    row-gap: 20px;
    height: auto;
    padding: 20px 15px;
  }
`;

const CatchCopy = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  font: ${theme.fonts.bold_22_33_vw};
  color: ${theme.colors.background};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    align-items: baseline;
    font: ${theme.fonts.bold_22_33};
  }
`;

const Lead = styled.span`
  font: ${theme.fonts.normal_16_32_vw};
  color: ${theme.colors.text};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    font: ${theme.fonts.normal_14_21};
  }
`;

const LeftArea = styled.div`
  position: absolute;
  width: 20vw;
  height: 16vw;
  padding: 2vw;
  top: 13vw;
  left: 1vw;
  z-index: -2;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    position: static;
    width: 100%;
    height: auto;
    padding: 10px 15px 17px;
    background-color: ${theme.colors.background};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const LeftAreaLabelWrapper = styled.div`
  width: 55vw;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: auto;
    white-space: nowrap;
    margin-right: 30px;
  }
`;

const LeftAreaPropertiesCount = styled.div<{ isVertical?: boolean }>`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  flex-direction: ${({ isVertical }) => (isVertical ? 'row' : 'column')};
`;

const LoanableAmountWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 1% 0;
`;

const LeftAreaLoanableAmount = styled.div<{ isVertical?: boolean }>`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'row' : 'column')};
  align-items: ${({ isVertical }) => (isVertical ? 'center' : 'baseline')};
`;

const LeftAreaLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_14_22_vw};
  color: ${theme.colors.white};
  padding-right: 2vw;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_12_22};
    padding-right: 10px;
  }
`;

const LeftAreaLabel2 = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_30_22_vw};
  margin: 1% 0;
  color: ${theme.colors.gold};
  padding-right: 2%;
  display: flex;
  align-items: baseline;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_30_22};
    margin: 1%;
  }
`;

const LeftAreaLabel3 = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_14_22_vw};
  color: ${theme.colors.white};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_13_22};
  }
`;

const BalloonBox = styled(Link)`
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  padding: 1vh 1vw;
  max-width: 100%;
  color: ${theme.colors.background};
  background: ${theme.colors.white};
  border: solid 1px ${theme.colors.gray};
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  font: ${theme.fonts.bold_12_18_vw};
  opacity: 1;
  text-decoration: none;
  & em {
    font: inherit;
    color: ${theme.colors.gold};
  }

  &::before {
    content: '';
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -15px;
    border: 12px solid transparent;
    border-bottom: 12px solid ${theme.colors.white};
    z-index: 1;
  }

  @media screen and (min-width: ${Breakpoints.sp}px) {
    &::after {
      content: '';
      position: absolute;
      top: -28px;
      left: 50%;
      margin-left: -17px;
      border: 14px solid transparent;
      border-bottom: 14px solid ${theme.colors.gray};
      z-index: 0;
    }
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font: ${theme.fonts.bold_12_18};
    height: 70px;
    width: 124px;
    padding: 0 10px;
    margin: 0;
    &::before {
      content: '';
      position: absolute;
      left: -10%;
      top: 37px;
      margin-left: -5px;
      border: 8px solid transparent;
      border-bottom: 14px solid ${theme.colors.white};
      transform: rotate(-90deg);
      z-index: 0;
    }
  }
`;

const OverviewArea = styled.div`
  position: absolute;
  top: ${toVw(190)};
  left: 0;
  z-index: -2;
  background: ${theme.colors.blue};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    position: static;
    width: 100%;
    height: 200px;
    padding: 22px 15px 31px;
  }
`;

const Overview = styled.dl`
  padding-left: ${toVw(10)};
  margin-bottom: ${toVw(36)};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-left: 8px;
    margin-bottom: 20px;
  }
`;

const Heading = styled.dt`
  text-align: left;
  margin-bottom: ${toVw(10)};
  font: ${theme.fonts.bold_16_22_vw};
  color: ${theme.colors.white};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 14px;
    font: ${theme.fonts.bold_14_22};
  }
`;

const Merit = styled.dd`
  text-align: left;
  font: ${theme.fonts.normal_15_32_vw};
  color: ${theme.colors.tableHeaderBackground};
  &::before {
    content: '・';
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_14_21};
  }
`;

const TrialOpenButton = styled.button`
  width: ${toVw(339)};
  height: ${toVw(64)};
  background: ${theme.colors.gold};
  font: ${theme.fonts.bold_16_32_vw};
  color: ${theme.colors.white};
  & > span {
    margin-left: ${toVw(10)};
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 50px;
    border-radius: 4px;

    font: ${theme.fonts.bold_16_23};
    & > span {
      display: none;
    }
  }
`;

const BuyerHeader = ({
  isAuthenticatedUser,
  isTrialMode,
  getLoanableAmounts,
  trialModeSwitch,
  setLoanableAmountsParam,
  filteringArea,
  getSpHeaderHeight,
  isOpenTrialModal,
}: Props) => {
  const [isTrialModeModalOpen, setIsTrialModeModalOpen] = useState(false);
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });

  useEffect(() => {
    if (isOpenTrialModal) openTrialModeModal();
  }, []);

  const propertiesCount = getLoanableAmounts.propertiesCount ?? 0;
  const { loanableAmount } = getLoanableAmounts;
  let displayLoanableAmount = loanableAmount ?? 0;
  if (loanableAmount && loanableAmount < 0) {
    // 借入可能金額が0万円以下だった場合、0円にする
    displayLoanableAmount = 0;
  }

  // お試し入力モーダルを開く
  const openTrialModeModal = () => {
    setIsTrialModeModalOpen(true);
  };

  // モーダルを閉じるだけ
  const closeTrialModeModal = () => {
    setIsTrialModeModalOpen(false);
  };

  // お試し物件一覧を表示
  const onChangeTrialMode = () => {
    trialModeSwitch(true);
  };

  useEffect(() => {
    if (getSpHeaderHeight) {
      const catchCopy_h = document.getElementById('CatchCopyArea')?.clientHeight;
      const headerBottom_h = document.getElementById('HeaderBottom')?.clientHeight;
      const header_h = 70 + (catchCopy_h as number) + (headerBottom_h as number);
      getSpHeaderHeight(header_h);
    }
  }, [isAuthenticatedUser, isTrialMode]);

  const createYenFormat = (yen: number) => {
    const [hundredMillionYen, tenThousandYen] = numberFormatter.numberToJpYenPrice(yen);

    return (
      <LoanableAmountWrapper>
        {hundredMillionYen && hundredMillionYen !== '0' && (
          <>
            <LeftAreaLabel2>{hundredMillionYen}</LeftAreaLabel2>
            <LeftAreaLabel3>億</LeftAreaLabel3>
          </>
        )}
        {tenThousandYen && tenThousandYen !== '0' && (
          <>
            <LeftAreaLabel2>{tenThousandYen}</LeftAreaLabel2>
            <LeftAreaLabel3>万</LeftAreaLabel3>
          </>
        )}
        {/* 0 円以下だった場合 */}
        {!hundredMillionYen && !tenThousandYen && <LeftAreaLabel2>0</LeftAreaLabel2>}
        <LeftAreaLabel3>円</LeftAreaLabel3>
      </LoanableAmountWrapper>
    );
  };

  return (
    <>
      <Header displayPattern="all" isBuyerTop filteringArea={filteringArea} openTrialModeModal={openTrialModeModal} />
      <CatchCopyArea id="CatchCopyArea">
        <CatchCopy>
          <SlashIcon />
          「買いたい」と「売りたい」をつなぐ
        </CatchCopy>
        <Lead>KUSABIは、投資用中古ワンルームマンションを適正価格で売買できるプラットフォームです。</Lead>
      </CatchCopyArea>

      <div id="HeaderBottom">
        {/* ログイン済み */}
        {isAuthenticatedUser && (
          <LeftArea>
            <LeftAreaLabelWrapper>
              <LeftAreaPropertiesCount isVertical={isSpMode}>
                <LeftAreaLabel>購入可能物件数</LeftAreaLabel>
                <LeftAreaLabel2>
                  {propertiesCount} <LeftAreaLabel3>件</LeftAreaLabel3>
                </LeftAreaLabel2>
              </LeftAreaPropertiesCount>
              <LeftAreaLoanableAmount isVertical={isSpMode}>
                <LeftAreaLabel>借入可能金額</LeftAreaLabel>
                {createYenFormat(displayLoanableAmount)}
              </LeftAreaLoanableAmount>
            </LeftAreaLabelWrapper>
          </LeftArea>
        )}

        {/* 未ログイン & お試しモード */}
        {!isAuthenticatedUser && isTrialMode && (
          <LeftArea>
            <LeftAreaLabelWrapper>
              <LeftAreaPropertiesCount isVertical={isTrialMode}>
                <LeftAreaLabel>購入可能物件数</LeftAreaLabel>
                <LeftAreaLabel2>{propertiesCount}</LeftAreaLabel2>
                <LeftAreaLabel3>件</LeftAreaLabel3>
              </LeftAreaPropertiesCount>
              <LeftAreaLoanableAmount isVertical={isTrialMode}>
                <LeftAreaLabel>借入可能金額</LeftAreaLabel>
                <LeftAreaLabel2>*****</LeftAreaLabel2>
                <LeftAreaLabel3>万円</LeftAreaLabel3>
              </LeftAreaLoanableAmount>
            </LeftAreaLabelWrapper>
            <BalloonBox to={Routings.accountRegistration.location}>
              <div>
                <em>会員登録</em>して
                <br />
                借入可能金額を確認する
              </div>
            </BalloonBox>
          </LeftArea>
        )}

        {/* 未ログイン & 通常モード */}
        {!isAuthenticatedUser && !isTrialMode && (
          <OverviewArea>
            <Overview>
              <Heading>KUSABIの買主様向けサービス</Heading>
              <Merit>借入可能金額を自動で算出</Merit>
              <Merit>フルローンで購入可能な物件がすぐにわかる</Merit>
            </Overview>
            <TrialOpenButton onClick={openTrialModeModal}>
              無料で会員限定機能を試す<span>↘</span>
            </TrialOpenButton>
          </OverviewArea>
        )}
      </div>

      {/* お試し入力モーダル */}
      {isTrialModeModalOpen && (
        <TrialFormModal
          isModalOpen={isTrialModeModalOpen}
          closeModal={closeTrialModeModal}
          onChangeTrialMode={onChangeTrialMode}
          setLoanableAmountsParam={setLoanableAmountsParam}
        />
      )}
    </>
  );
};

export default BuyerHeader;
