/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { format as fnsFormat, parse } from 'date-fns';

import { containsSurrogatePairs, isBlank } from '@/common/utilities';

/**
 * メールアドレスフォーマット
 */
export const EMAIL_REX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * URLフォーマット
 */
export const URL_REX = /^(https|http)(:\/\/[-_.!~*¥'()a-zA-Z0-9;¥/?:¥@&=+¥$,%#]+)$/;

/**
 * 数値タイプEnum
 */
export enum NumberType {
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
}

/**
 * [type="email"]バリデーション用引数Interface
 */
interface ValidateEmailArgs {
  value: string | null; // 対象値
  maxLength?: number; // 最大文字数
  required?: boolean; // 必須チェックの有無
}

/**
 * [type="text"]バリデーション用引数Interface
 */
interface ValidateTextArgs {
  value: string | null; // 対象値
  checkSurrogatePairs?: boolean; // 使用不可文字列チェックフラグ
  minLength?: number; // 最小文字数
  maxLength?: number; // 最大文字数
  maxRows?: number; // 最大行数
  pattern?: RegExp; // 正規表現
  required?: boolean; // 必須チェックの有無
}

/**
 * [type="number"]バリデーション用引数Interface
 */
interface ValidateNumberArgs {
  value: any; // 対象値
  numberType?: NumberType; // 数値タイプ
  maxDigit?: number; // 最大桁数
  maxAfterDecimalPointDigit?: number; // 小数点最大数
  min?: number; // 最小値
  max?: number; // 最大値
  required?: boolean; // 必須チェックの有無
}

/**
 * [type="date"]バリデーション用引数Interface
 */
interface ValidateDateArgs {
  value: any; // 対象値
  format: string; // 日付フォーマット文字列
  required?: boolean; // 必須チェックの有無
}

/**
 * [type="url"]バリデーション用引数Interface
 */
interface ValidateUrlArgs {
  value: string | null; // 対象値
  maxLength?: number; // 最大文字数
  required?: boolean; // 必須チェックの有無
}

/**
 * 重複チェックバリデーション用引数Interface
 */
interface ValidateDuplicateArgs {
  value: string | null; // 対象値
  checkList: any[]; // 比較対象群
  duplicateItemName: string; // 項目名
}

/**
 * バリデーション結果保持クラス
 */
export class ValidationResult {
  constructor(private _invalid: boolean = false, private _message: string[] = []) {}

  setInvalid(bool: boolean) {
    this._invalid = bool;
  }

  getInvalid() {
    return this._invalid;
  }

  setMessage(message: string) {
    this._message.push(message);
  }

  getMessageAll(): string[] {
    return this._message;
  }

  getMessage(idx = 0): string {
    return this._message[idx] || '';
  }
}

/**
 * [type="email"]チェック
 * @param param0 ValidateEmailArgs
 */
const validateEmail = ({ value, maxLength, required }: ValidateEmailArgs) => {
  const validationResult = new ValidationResult();

  if (isBlank(`${value}`)) {
    // 必須チェックあり
    if (required) {
      validationResult.setInvalid(true);
      validationResult.setMessage('必須項目になります');
      return validationResult;
    } else {
      // 必須チェックなしの場合は処理終了
      return validationResult;
    }
  }

  // フォーマットチェック
  if (!EMAIL_REX.test(value!)) {
    validationResult.setInvalid(true);
    validationResult.setMessage('メールアドレスの形式で入力してください');
    return validationResult;
  }

  // 文字数チェック
  if (maxLength && maxLength < value!.length) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`${maxLength}文字以内で入力してください`);
    return validationResult;
  }

  return validationResult;
};

/**
 * [type="text"]チェック
 * @param param0 ValidateTextArgs
 */
const validateText = ({
  value,
  checkSurrogatePairs = true,
  minLength,
  maxLength,
  maxRows,
  pattern,
  required,
}: ValidateTextArgs) => {
  const validationResult = new ValidationResult();

  if (isBlank(`${value}`)) {
    // 必須チェックあり
    if (required) {
      validationResult.setInvalid(true);
      validationResult.setMessage('必須項目になります');
      return validationResult;
    } else {
      // 必須チェックなしの場合は処理終了
      return validationResult;
    }
  }

  // 文字数チェック
  if (minLength && maxLength && (value!.length < minLength || maxLength < value!.length)) {
    // 範囲
    validationResult.setInvalid(true);
    validationResult.setMessage(`${minLength}～${maxLength}文字の範囲で入力してください`);
    return validationResult;
  }
  // 最小
  if (minLength && value!.length < minLength) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`${maxLength}文字以上で入力してください`);
    return validationResult;
  }
  // 最大
  if (maxLength && value!.length > maxLength) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`${maxLength}文字以内で入力してください`);
    return validationResult;
  }
  // 行数チェック
  if (maxRows && value!.split('\n').length > maxRows) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`${maxRows}行以内で入力してください`);
    return validationResult;
  }
  // 使用不可文字列チェック
  if (checkSurrogatePairs && containsSurrogatePairs(value!)) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`絵文字など使用できない文字が含まれています`);
    return validationResult;
  }
  // 正規表現での形式チェック
  if (pattern && !pattern.test(value!)) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`正しい形式で入力してください`);
    return validationResult;
  }

  return validationResult;
};

/**
 * [type="date"]チェック
 * @param param0 ValidateDateArgs
 */
const validateDate = ({ value, format, required }: ValidateDateArgs) => {
  const validationResult = new ValidationResult();

  if (isBlank(`${value}`)) {
    // 必須チェックあり
    if (required) {
      validationResult.setInvalid(true);
      validationResult.setMessage('必須項目になります');
      return validationResult;
    } else {
      // 必須チェックなしの場合は処理終了
      return validationResult;
    }
  }

  // 日付フォーマットチェック
  if (fnsFormat(parse(value, format, new Date()), format) !== value) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`正しい形式で入力してください`);
    return validationResult;
  }

  return validationResult;
};

/**
 * [type="url"]チェック
 * @param param0 ValidateUrlArgs
 */
const validateUrl = ({ value, maxLength, required }: ValidateUrlArgs) => {
  const validationResult = new ValidationResult();

  if (isBlank(`${value}`)) {
    // 必須チェックあり
    if (required) {
      validationResult.setInvalid(true);
      validationResult.setMessage('必須項目になります');
      return validationResult;
    } else {
      // 必須チェックなしの場合は処理終了
      return validationResult;
    }
  }

  // フォーマットチェック
  if (!URL_REX.test(value!)) {
    validationResult.setInvalid(true);
    validationResult.setMessage('URLの形式で入力してください');
    return validationResult;
  }

  // 文字数チェック
  if (maxLength && maxLength < value!.length) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`${maxLength}文字以内で入力してください`);
    return validationResult;
  }

  return validationResult;
};

/**
 * 重複チェック
 * @param param0 ValidateEmailArgs
 */
const validateDuplicate = ({ value, checkList, duplicateItemName }: ValidateDuplicateArgs) => {
  const validationResult = new ValidationResult();
  if (checkList.some((v) => v === value)) {
    validationResult.setInvalid(true);
    validationResult.setMessage(`すでに登録されている${duplicateItemName}です`);
    return validationResult;
  }
  return validationResult;
};

/**
 * バリデーションがすべて正常かチェック
 * @param args ValidationResult
 */
const isValid = (...args: ValidationResult[]) => args.every((res) => !res.getInvalid());

export type ValidateArgs =
  | ValidateEmailArgs
  | ValidateTextArgs
  | ValidateNumberArgs
  | ValidateDateArgs
  | ValidateUrlArgs;

export default {
  validateEmail,
  validateText,
  validateDate,
  validateUrl,
  validateDuplicate,
  isValid,
};

export const PropertySortSelectBox = {
  NEW_ARRIVAL_DESC: '成約日順',
  SALES_PRICE_DESC: '価格の高い順',
  SALES_PRICE_ASC: '価格の安い順',
  BUILT_DATE_DESC: '築年数の古い順',
  BUILT_DATE_ASC: '築年数の新しい順',
  WALK_MINUTE_DESC: '駅徒歩が遠い順',
  WALK_MINUTE_ASC: '駅徒歩が近い順',
} as const;

export type PropertySortSelectBox = typeof PropertySortSelectBox[keyof typeof PropertySortSelectBox];
