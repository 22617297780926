import { PropertyStatus } from 'openapi/kusabi-frontgw-spec';
import React from 'react';
import styled from 'styled-components';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';

const Marker = styled.span`
  height: 22px;
  border-radius: 20px;
  font: ${theme.fonts.bold_12_22};
  letter-spacing: 0;
  color: ${theme.colors.white};
  padding-bottom: 2px;
  margin-top: 2px;
  text-align: center;
  opacity: 1;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 0 10px 2px 10px;
  }
`;

const NewMarker = styled(Marker)`
  width: 40px;
  background: ${theme.colors.markerNew} 0% 0% no-repeat padding-box;
`;

const PriceEditMarker = styled(Marker)`
  width: 50px;
  background: ${theme.colors.markerNew} 0% 0% no-repeat padding-box;
`;

const ContractMarker = styled(Marker)`
  width: 50px;
  background: ${theme.colors.markerContract} 0% 0% no-repeat padding-box;
`;

type Props = {
  propertyStatus: PropertyStatus;
  isNew?: boolean;
  hasPriceChanged?: boolean;
};

const PropertyMarker = (props: Props) => {
  const { propertyStatus, isNew, hasPriceChanged } = props;

  return propertyStatus === PropertyStatus.SOLD ? (
    <ContractMarker>成約済</ContractMarker>
  ) : (
    <>
      {isNew && <NewMarker>新着</NewMarker>}
      {!isNew && hasPriceChanged && <PriceEditMarker>新価格</PriceEditMarker>}
    </>
  );
};

export default PropertyMarker;
