import styled from 'styled-components';
import theme from '@/styles/theme';

const Select = styled.select`
  width: 220px;
  height: 41px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${theme.colors.sea};
  padding-left: 20px;
  padding-right: 10px;
  appearance: none;
  font: ${theme.fonts.normal_16_27};
  color: ${theme.colors.blue};
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 7px;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%234C586F%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%20%2F%3E%3Cpath%20d%3D%22M12%205.83L15.17%209l1.41-1.41L12%203%207.41%207.59%208.83%209%2012%205.83zm0%2012.34L8.83%2015l-1.41%201.41L12%2021l4.59-4.59L15.17%2015%2012%2018.17z%22%20%2F%3E%3C%2Fsvg%3E');
`;

export default Select;
