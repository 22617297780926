import theme from '@/styles/theme';
import React, { memo } from 'react';

const colors = theme.colors;

const rotateAngle = {
  right: 0,
  down: 90,
  left: 180,
  up: -90,
  rightDown: 45,
} as const;

type Props = {
  direction?: keyof typeof rotateAngle;
  color?: keyof typeof colors;
};

const ArrowIcon = ({ direction = 'right', color = 'gold' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={colors[color]}
      style={{ transform: `rotate(${rotateAngle[direction]}deg)` }}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
    </svg>
  );
};

export default memo(ArrowIcon);
